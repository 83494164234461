(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-additions-components/assets/javascripts/bet-amounts.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-additions-components/assets/javascripts/bet-amounts.js');
"use strict";

const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  useState,
  useEffect,
  useRef,
  useCallback
} = React;
const {
  ReactIcon
} = svs.ui;
const {
  format
} = svs.utils;
const {
  RadioGroup,
  ControlGroup,
  RadioButton,
  Fieldset,
  Form,
  Inputv2
} = svs.ui.reactForm;
const {
  actions
} = svs.components.tipsen.engine;
const {
  selectBetAmounts
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectCurrentBetAmount
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  BaseAddons
} = svs.components.tipsen.baseAddons;


function restArguments(func, startIndex) {
  startIndex = startIndex === undefined || startIndex === null ? func.length - 1 : +Number(startIndex);
  return function () {
    const length = Math.max(arguments.length - startIndex, 0);
    const rest = Array(length);
    let index = 0;
    for (; index < length; index++) {
      rest[index] = arguments[index + startIndex];
    }
    switch (startIndex) {
      case 0:
        return func.call(this, rest);
      case 1:
        return func.call(this, arguments[0], rest);
      case 2:
        return func.call(this, arguments[0], arguments[1], rest);
    }
    const args = Array(startIndex + 1);
    for (index = 0; index < startIndex; index++) {
      args[index] = arguments[index];
    }
    args[startIndex] = rest;
    return func.apply(this, args);
  };
}
function debounce(func, wait, immediate) {
  let timeout;
  let previous;
  let args;
  let result;
  let context;
  const later = function () {
    const passed = Date.now() - previous;
    if (wait > passed) {
      timeout = setTimeout(later, wait - passed);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
      }
      if (!timeout) {
        args = context = null;
      }
    }
  };
  const debounced = restArguments(function (_args) {
    context = this;
    args = _args;
    previous = Date.now();
    if (!timeout) {
      timeout = setTimeout(later, wait);
      if (immediate) {
        result = func.apply(context, args);
      }
    }
    return result;
  });
  debounced.cancel = function () {
    clearTimeout(timeout);
    timeout = args = context = null;
  };
  return debounced;
}


const BetAmounts = () => {
  const dispatch = useDispatch();
  const couponId = useCouponId();
  const betAmounts = useSelector(selectBetAmounts);
  const currentBetAmount = useSelector(state => selectCurrentBetAmount(state, couponId));
  const oldBetAmount = useRef(currentBetAmount);
  const engineDefinition = useSelector(selectEngineDefinition);
  const {
    maxBetAmount
  } = engineDefinition;
  const isSmallDevice = useMediaQuery(breakpoints.down(BREAKPOINT_MD));
  const [betAmountInput, setBetAmountInput] = useState(currentBetAmount);

  useEffect(() => {
    if (oldBetAmount.current !== currentBetAmount) {
      oldBetAmount.current = currentBetAmount;
      setBetAmountInput(currentBetAmount);
    }
  }, [currentBetAmount]);
  const [betAmountErrors, setBetAmountErrors] = useState({
    error: false,
    message: ''
  });
  const setError = useCallback(message => {
    setBetAmountErrors({
      error: true,
      message
    });
  }, []);
  const removeError = () => {
    setBetAmountErrors({
      error: false,
      message: ''
    });
  };
  const debouncer = useRef();
  useEffect(() => {
    debouncer.current = debounce(value => {
      if (value < 1 || value > maxBetAmount) {
        setError("V\xE4lj ett belopp mellan 1 och ".concat(maxBetAmount, " kr"));
      }
    }, 500);
    return () => {
      debouncer.current.cancel();
    };
  }, [setError, maxBetAmount]);
  const handleChange = e => {
    const input = e.target.value.replace(/[^0-9]/g, '');
    const value = Number(input);
    const rangeIsOk = value > 0 && value <= maxBetAmount;
    if (!Number.isNaN(value) && value) {
      debouncer.current(value);
    }
    if (!value || value.length === 0 || rangeIsOk) {
      removeError();
      debouncer.current.cancel();
    }
    if (rangeIsOk) {
      dispatch(actions.selectBetAmount({
        couponId,
        betAmount: value
      }));
    }
    setBetAmountInput(value ? value : '');
  };
  const handleSubmit = betAmount => {
    setBetAmountInput(betAmount);
    dispatch(actions.selectBetAmount({
      couponId,
      betAmount
    }));
  };
  return React.createElement(BaseAddons, {
    dialog: "Eventuell vinst multipliceras med insats per rad (2 kr per rad ger exempelvis 2 g\xE5nger vinst).",
    icon: "money",
    id: "betamount",
    title: "Insats per rad"
  }, React.createElement("div", {
    className: "tipsen-addition-bet-amount"
  }, React.createElement("div", null, React.createElement("div", {
    className: "bet-amount-desc"
  }, betAmounts.map((amount, index) => index ? React.createElement("div", {
    className: "pg_tipsen_bet_amount_desc__item",
    key: amount
  }, React.createElement("span", null, "".concat(amount, " x")), React.createElement("span", null, "vinst")) : null)), React.createElement(Form, {
    className: "tipsen-addition-form"
  }, React.createElement(Fieldset, null, React.createElement("legend", {
    className: "sr-only"
  }, "Insats per rad"), React.createElement(ControlGroup, null, React.createElement(RadioGroup, {
    size: "200"
  }, betAmounts.map(betAmount => {
    const checked = betAmount === currentBetAmount;
    return React.createElement(RadioButton, {
      "aria-checked": checked,
      checked: checked,
      className: checked ? 'f-bold' : '',
      key: betAmount,
      onChange: () => handleSubmit(betAmount),
      tabIndex: checked ? 0 : -1,
      value: betAmount
    }, format.Currency(betAmount), ' ', "kr");
  })))))), maxBetAmount > 0 && React.createElement(Form, {
    className: "tipsen-addition-bet-amount__input",
    isVertical: true,
    onSubmit: e => e.preventDefault()
  }, React.createElement(ControlGroup, {
    isLessSpace: true,
    v2: true
  }, React.createElement(Inputv2, {
    id: "betAmountInput",
    inputHelpText: isSmallDevice ? 'Insats' : 'Välj egen insats',
    label: isSmallDevice ? 'Insats' : 'Välj egen insats',
    min: "0",
    onChange: handleChange,
    suffix: "kr",
    type: "tel",
    value: betAmountInput
  })))), betAmountErrors.error && React.createElement("div", {
    className: "tipsen-addition-bet-amount__input-error"
  }, React.createElement(ReactIcon, {
    color: "fc-red",
    icon: "help-2",
    size: "300"
  }), betAmountErrors.message));
};
setGlobal('svs.components.tipsen.couponAdditionsComponents.BetAmounts', BetAmounts);

 })(window);